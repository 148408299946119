import React, { useState } from 'react';
import api from '../utils/api';

// Specific Popup when user enrolls in a project

const ProjectPopup = ({ isOpen, onClose, projectId, projectName, userId}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!firstName || !lastName || !phoneNumber || !email) {
      setError('All fields are required.');
      return;
    }

    try {
      await api.post('/projects/signup', {
        userId,
        firstName,
        lastName,
        phoneNumber,
        email,
        projectId,
        projectName,
      });
      setSuccess(true);
    } catch (err) {
      setError('Failed to enroll. Please try again later.');
      console.error('Error enrolling:', err);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup box */}
      <div className="relative bg-white w-11/12 max-w-md mx-auto p-8 text-center rounded-lg shadow-lg z-10">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Content */}
        <h2 className="text-2xl font-bold mt-4 mb-6">Enroll in {projectName} Project</h2>

        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-primary mb-4">Enrollment successful! You will be contacted with further steps.</p>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 rounded text-center block hover:bg-primary-darker"
          >
            Enroll
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProjectPopup;
