import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdDownload } from "react-icons/io";
import { IoPlayCircleOutline, IoCheckmarkCircle, IoChevronBackOutline } from "react-icons/io5";
import { useCompletion } from '../context/completionContext';
import { useAuth } from '../context/authContext';
import Popup from './Popup';
import api from '../utils/api';



const Course = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { userId } = useAuth();
  const { markCourseAsCompleted, seriesCompleted } = useCompletion();
  const [nextCourse, setNextCourse] = useState(null);
  const [course, setCourse] = useState(null);
  const [videoSegments, setVideoSegments] = useState([]);
  const [slideDeck, setSlideDeck] = useState(null);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [completedSegments, setCompletedSegments] = useState([]);
  const [singleVideoPath, setSingleVideoPath] = useState(null); 


  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await api.get(`/api/courses/${courseId}`);
        setCourse(response.data.course); // Set course data, including signed URLs
        setSlideDeck(response.data.slideDeck);
        if (response.data.video_segments?.length > 0) {
          setVideoSegments(response.data.video_segments);
        } else if (response.data.single_video_path) {
          setSingleVideoPath(response.data.single_video_path);
        }
        console.log("fetched the response data:", response.data )
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };
    fetchCourse();
  }, [courseId]);

  const fetchNextCourse = async () => {
    try {
      const response = await api.get('/api/courses/next', { params: { courseId } });
      setNextCourse(response.data);
    } catch (error) {
      console.error('Error fetching next course:', error);
    }
  };

  const course_name = course?.course_name || "Course Title Not Available";

  const handleBackClick = () => {
    navigate(`/course_details/${courseId}`);
  };

  const handleSegmentClick = (index) => {
    setCurrentSegment(index);
  };

  const handleVideoEnd = async () => {
    if (course.type !== 'course') return;

    if (videoSegments.length > 0) {
      // Handle segmented videos, set current as completed
      setCompletedSegments((prev) => [...new Set([...prev, currentSegment])]);
  
      if (completedSegments.length + 1 === videoSegments.length) {
        // Mark course as completed if all segments are finished
        await markCourseAsCompleted(courseId);
  
        if ([1, 2, 3].includes(Number(courseId))) {
          // Manually check if the series is completed
          const response = await api.get(`/api/user/completions/${userId}`);
          const isSeriesCompleted = response.data.isSeriesCompleted;
          // console.log("Course series completed?", isSeriesCompleted) // Debugging

          // Wait for `seriesCompleted` to update
          if (isSeriesCompleted) {
            console.log("Series is completed. Showing certificate popup window.");
            setNextCourse(null);
          } else {
            await fetchNextCourse();
          } 
        } else{
            await fetchNextCourse();
          }
      } else if (currentSegment < videoSegments.length - 1) {
        // Otherwise move to the next video segment
        setCurrentSegment((prev) => prev + 1);
      }
    } else if (singleVideoPath) {
      // Handle single video completion
      await markCourseAsCompleted(courseId);
  
      // Wait for `seriesCompleted` to update
      if (seriesCompleted) {
        console.log("Series is completed. Showing certificate popup.");
        setNextCourse(null); // Ensure no next course is fetched
      } else {
        try {
          console.log("Fetching next course...");
          const response = await api.get('/api/courses/next', { params: { courseId } });
          setNextCourse(response.data);
        } catch (error) {
          console.error('Error fetching next course:', error);
        }
      }
    }
  };

  // TODO: Make a standard loading page
  if (!course) {
    return <div>Loading course details...</div>;
  }
 
  return (
    <div className="container mx-auto px-4 py-6">
      <button onClick={handleBackClick} className="flex items-center mb-4 px-2 py-2 text-gray-800 font-regular">
        <IoChevronBackOutline className="mr-2 text-lg" />
        Back to Course Description
      </button>
      <h1 className="text-2xl font-semibold">{course_name || "Course Title Not Available"}</h1>
      
      <div className="flex mt-8">
        {/* Sidebar for Video Segments */}
        {videoSegments.length > 0 && (
          <div className="w-1/4 bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-bold mb-4">Progress</h2>
            <ul>
              {videoSegments.map((segment, index) => (
                <li
                  key={segment.id}
                  className={`cursor-pointer flex items-center space-x-2 mb-2 p-2 rounded ${
                    currentSegment === index ? 'bg-gray-200' : 'bg-white'
                  }`}
                  onClick={() => handleSegmentClick(index)}
                >
                  {/* Icons to show video segment progress */}
                  <div>
                    {completedSegments.includes(index) ? (
                      <IoCheckmarkCircle className="text-primary text-lg w-[30px] h-[30px]" />
                    ) : currentSegment === index ? (
                      <IoPlayCircleOutline className="text-primary text-lg w-[30px] h-[30px]" />
                    ) : (
                      <IoPlayCircleOutline className="text-gray-300 text-lg w-[30px] h-[30px]" />
                    )}
                  </div>
                  <div>
                    <p className="font-medium">{segment.segment_title}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Main Video Player */}
        <div className={`p-2 ${videoSegments.length > 0 ? 'ml-8 w-3/4' : 'w-full'}`}>
          <h2 className="text-lg font-bold">Course Video:</h2>
          {(videoSegments.length > 0 ? videoSegments[currentSegment]?.video_url : singleVideoPath) ? (
            <video
              id="course-video"
              controls
              className="w-full mt-2"
              src={videoSegments.length > 0 ? videoSegments[currentSegment]?.video_url : singleVideoPath}
              onEnded={handleVideoEnd}
            />
          ) : (
            <p className="text-gray-500">Video not available for this course.</p>
          )}

          {/* Slide Deck Download Button */}
          <div className="mt-4">
            <h2 className="text-lg font-bold">Download Slide Deck:</h2>
            {slideDeck ? (
              <a
                href={slideDeck}
                target="_blank"
                rel="noopener noreferrer"
                download={`${course_name}_slides.pdf`}
                className="flex items-center text-primary"
              >
                <IoMdDownload className="mr-2 text-3xl" />
                Download Slide Deck
              </a>
            ) : (
              <p className="text-gray-500">Slide deck not available for this course.</p>
            )}
          </div>
        </div>
      </div>


      {/* Display Popup for either all courses completed or next course */}
      {seriesCompleted && [1, 2, 3].includes(Number(courseId)) ? (
        <Popup
          allCoursesCompleted={true}
          nextCourse={null}
          surveyLink="https://surveypluto.com/q/7yuKogbCV" //TODO: Change with updated surveypluto link once received
          courseId={courseId}
          userId={userId}
        />
      ) : nextCourse ? (
        <Popup
          allCoursesCompleted={false}
          nextCourse={nextCourse}
          surveyLink=""
          courseId={courseId}
          userId={userId}
        />
      ) : null}
    </div>
  );
};

export default Course;
